import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import HealthHistory from "views/HealthHistory";
import PatientDashboard from "views/PatientDashboard";
import PDFViewer from "views/PDFViewer";
import LabScan from "views/LabScan";
import InvalidLinkMessage from "ui-component/InvalidLinkMessage";


// LoginPage routing
const LoginPage = Loadable(lazy(() => import("views/loginPage/login")));
const OtpPage = Loadable(lazy(() => import("views/loginPage/otp")));

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// const Documentation = Loadable(lazy(() => import("views/documentation")));

// sample page routing

const NewEmbedTableauPage = Loadable(
  lazy(() => import("views/newTableauPage"))
);

const ViewVitalsPage = Loadable(lazy(() => import("views/viewVitalsPage")));


const MenstrualPage = Loadable(lazy(() => import("views/MenstrualPage")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  children: [
    {
      path: "/hlog/landing",
      element: <DashboardDefault />,
    },
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/hlog/otp-verify",
      element: <OtpPage />,
    },
    {
      path: "/hlog/tableau-page",
      element: <NewEmbedTableauPage />,
    },
    {
      path: "/hlog/view-vitals",
      element: <ViewVitalsPage />,
    },
    {
      path: "/hlog/menstrual",
      element: <MenstrualPage />,
    },
    {
      path: "/hlog/health-history",
      element: <HealthHistory />,
    },
    {
      path: "/hlog/dashboard",
      element: <PatientDashboard />,
    },
    {
      path: "/hlog/pdf",
      element: <PDFViewer />,
    },
    {
      path: "/hlog/labScanReports",
      element: <LabScan/>,
    },
    // Wildcard route for unmatched paths
    {
      path: "*",
      element: <InvalidLinkMessage />, // Display your 404 component
    },
    // {
    //   path: "/documentation",
    //   element: <Documentation />,
    // },
  ],
};
export default MainRoutes;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SET_USER_NAME, SET_PERSON_DETAILS } from "store/actions";

const usePersistedState = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
        const serializedState1 = sessionStorage.getItem('reduxState1');
        const serializedState2 = sessionStorage.getItem('reduxState2');
        if (serializedState1) {
          const parsedState1 = JSON.parse(serializedState1);
          dispatch({ type: SET_USER_NAME, data: parsedState1 });
        }
        if (serializedState2) {
          const parsedState2 = JSON.parse(serializedState2);
          dispatch({ type: SET_PERSON_DETAILS, data: parsedState2 });
        }
      } catch (err) {
        console.error("Could not load state from localStorage", err);
      }
  }, [dispatch]);
};

export default usePersistedState;

import React, {useEffect, useRef, useState} from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Typography, IconButton, Grid, Box} from '@mui/material';
import moment from 'moment';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import {fetchHealtHistory} from 'services/Api';
import useExtractRawQueryParams from 'hooks/useExtractQueryParams';
import NameTile from 'ui-component/NameTile';
import Logo from 'ui-component/Logo';
import BackButton from 'ui-component/backButton/BackButton';
import usePersistedState from 'hooks/usePersistedState';

const Section = ({data}) => (
  <Box
    border={1}
    borderColor='#33CCCC'
    p={2}
    borderRadius={2}
    mb={1}
  >
    {Object.entries(data).map(([key, value]) => (
      <Grid container alignItems='flex-start'>
        <Grid item xs={4}>
          <Typography variant="subtitle1" m={0} p={0} color='#33CCCC' fontWeight='regular'>{key}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1" color='white' fontWeight='bold'>{value}</Typography>
        </Grid>
      </Grid>
    ))}
  </Box>
);

const HealthHistory = () => {
  usePersistedState();
  const navigate = useNavigate();
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const customization = useSelector((state) => state.customization);
  const { encryptedValue: queryEncryptedValue, key: queryKey } = useExtractRawQueryParams();
  const [encryptedValue, setEncryptedValue] = useState(queryEncryptedValue);
  const [key, setKey] = useState(queryKey);
  const hlogPersonId = parseInt(customization.userData.hlogPersonId, 0);

  const parseData = (data) => {
    const {result: {patientTreatmentDetails, patientAllergyDetails, patientOperationDetails, patientAccidentDetails} = {}} = data ?? {};

    const healthConditions = patientTreatmentDetails?.map(t => ({
      'Health conditions': t.treatingHealthConditions,
      'Hospital/Clinic': t.hospitalName,
      'Area/City': `${t.area?.trim()},${t.city}`
    }));

    const allergies = patientAllergyDetails?.map(a => ({
      'Allergies': a.allergeticMedicine
    }));

    const operations = patientOperationDetails?.map(o => ({
      'Operation Performed': o.operationName,
      'Operation Date': moment(o.operationDate).format('DD/MM/YYYY'),
      'Hospital/Clinic': o.hospitalName,
      'Area/City': `${o.area?.trim()},${o.city}`
    }));

    const accidents = patientAccidentDetails?.map(a => a.isOperationDone ?
      {
        'Accident Date': moment(a.accidentDate).format('DD/MM/YYYY'),
        'Accident Injury': a.accidentInjury,
        'Operation Performed': a.operationName,
        'Operation Date': moment(a.operationDate).format('DD/MM/YYYY'),
        'Hospital/Clinic': a.hospitalName,
        'Area/City': `${a.area?.trim()},${a.city}`
      }
      : {
        'Accident Date': moment(a.accidentDate).format('DD/MM/YYYY'),
        'Accident Injury': a.accidentInjury,
        'Hospital/Clinic': a.hospitalName,
        'Area/City': `${a.area?.trim()},${a.city}`
      });

    setHistory([
      ...healthConditions,
      ...operations,
      ...accidents,
      ...allergies,
    ]);
  };

  useEffect(() => {
    const matchedPerson = customization?.personData?.find(person =>
      person?.personDetails?.firstName === customization?.userData?.firstName &&
      person?.personDetails?.age === customization?.userData?.age
    );
    if (matchedPerson) {
      setEncryptedValue(matchedPerson.encryptedValue);
      setKey(matchedPerson.uid);
    } else {
      setEncryptedValue(queryEncryptedValue);
      setKey(queryKey);
    }
  }, [customization?.personData, customization?.userData?.firstName, customization?.userData?.age, queryEncryptedValue, queryKey]);

  useEffect(async () => {
    if(encryptedValue, key) {
    try {
      const response = await fetchHealtHistory(encryptedValue, key);
      parseData(response);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
    }
  }, [encryptedValue, key]);

  return (
    <Box style={styles.container}>
            <div>
        <BackButton onClick={() => navigate("/hlog/dashboard" + window.location.search)}/>
      </div>
      <header style={styles.header}>
      <Logo/>
        <Typography variant="h3" color="white" fontWeight="bold">
          View Health History
        </Typography>
      </header>
      <NameTile/>

      {loading
        ? <Label text='Loading...' />
        : history?.length
          ? history?.map(detail => <Section data={detail} />)
          : <Label text='No health information recorded by the user.' />
      }
    </Box >
  );
};

const Label = ({text}) => <Typography variant="h3" color='white' style={{textAlign: 'center', marginTop: 30}}>{text}</Typography>;

const styles = {
  header: {
    textAlign: 'center',
    width: '100%',
    marginTop: '0px',
  },
  container: {
    backgroundColor: '#2c3e50',
    minHeight: '100vh', // Ensures the container takes at least the full height of the viewport
    padding: '20px',
  },
};


export default HealthHistory;
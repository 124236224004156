import React from 'react';

const InvalidLinkMessage = () => {
  const handleLoginRedirect = () => {
    window.location.href = '/'; 
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Link is Invalid or Expired!</h1>
      <p style={styles.message}>
        The link you are trying to access is no longer valid. Please check the link or request a new one.
      </p>
      <span style={styles.loginContainer}>
        <h1 style={styles.login}>Are you a Medugo user?</h1>
        <span 
          style={styles.loginLink}
          onClick={handleLoginRedirect}
        >
          click here to login
        </span>
      </span>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    color: '#343a40',
    textAlign: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  },
  heading: {
    fontSize: '2em',
    marginBottom: '20px',
    color: '#eee',
    lineHeight: '1em'
  },
  message: {
    fontSize: '1.2em',
    maxWidth: '400px',
    margin: '0 20px',
    color: '#eee'
  },
  loginContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '4px',
  },
  login: {
    fontSize: '1.2em',
    color: '#eee',
    marginRight: '8px', 
  },
  loginLink: {
    fontSize: '1.2em',
    color: '#4ea4ff',
    textDecoration: 'underline', 
    cursor: 'pointer', 
  },
};

export default InvalidLinkMessage;

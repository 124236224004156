import { Card, FormControl, Grid, Select, Typography } from '@mui/material';
import BabyVaccinationSvg from 'assets/images/BabyVaccinationSvg';
import MenstrualSvg from 'assets/images/MenstrualSvg';
import HealthVitalSvg from 'assets/images/HealthVItalSvg';
import HealthHistorySvg from 'assets/images/HealthHistorySvg';
import LabScanSvg from 'assets/images/LabScanSvg';
import RightArrowSvg from 'assets/images/icons/RightArrowSvg';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useSelector } from 'react-redux';
import useExtractRawQueryParams from 'hooks/useExtractQueryParams';
import NameTile from 'ui-component/NameTile';
import Logo from 'ui-component/Logo';
import { borderColor, Box, height, useMediaQuery } from '@mui/system';
import PrescriptionsSvg from 'assets/images/PrescriptionsSvg';
import { webLogout } from 'services/Api';
import DischargeSummarySvg from 'assets/images/DischargeSummarySvg';
import ScansAndXrayReportsSvg from 'assets/images/ScansAndXrayReportsSvg';
import {toast} from "react-toastify";
import DrawerPerson from './loginPage/drawer';
import { MenuItem, ListItemIcon, Avatar } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import usePersistedState from 'hooks/usePersistedState';

const items = [
  {
    label: "Lab Reports",
    value: 'lab-scan',
    route: '/hlog/tableau-page',
    icon: LabScanSvg,
    reportKey: "labScanStatus"
  },
  {
    label: "Scans and X Ray Reports",
    value: 'lab-scan',
    route: '/hlog/labScanReports',
    icon: ScansAndXrayReportsSvg,
    reportKey: "labScanStatus"
  },
  {
    label: "Vaccination Records",
    value: 'bv',
    uploadType: "BVR",
    route: '/hlog/pdf',
    icon: BabyVaccinationSvg,
    reportKey: "babyVaccinationStatus"
  },
  {
    label: "Health History",
    value: 'health-history',
    route: '/hlog/health-history',
    icon: HealthHistorySvg,
    reportKey: "healthHistoryStatus"
  },
  {
    label: "Health Vitals",
    value: 'health-vitals',
    route: '/hlog/view-vitals',
    icon: HealthVitalSvg,
    reportKey: "healthVitalsStatus"
  },
  {
    label: "Dr Prescriptions",
    value: 'drp',
    uploadType: "DRP",
    route: '/hlog/pdf',
    icon: PrescriptionsSvg,
    reportKey: "drPrescriptionStatus"
  },
  {
    label: "Discharge Summary",
    value: 'ds',
    uploadType: "DS",
    route: '/hlog/pdf',
    icon: DischargeSummarySvg,
    reportKey: "dischargeSummaryStatus"
  },
];

const ReportCard = ({ title, Icon, disabled, onClick }) => {
  const theme = useTheme();
  const styles = {
    reportCard: {
      border: '1px solid #49C1C3',
      borderRadius: '5px',
      margin: '5px 0',
      padding: '17px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: disabled ? '#41586E' : theme.palette.background.paper,
      transition: 'background-color 0.3s ease',
    },
  };

  return (
    <Card style={{
      ...styles.reportCard,
      backgroundColor: disabled ? '#41586E' : theme.paper,
      opacity: disabled ? 0.4 : 1
    }}
      onClick={() => !disabled && onClick()}>
      <Icon disabled={disabled} />
      <Typography variant="h3" color='white' fontWeight='bold' textAlign='left' flex={1} marginLeft={2}>{title}</Typography>
      <RightArrowSvg disabled={disabled} />
    </Card>
  );
};

const PatientDashboard = () => {
  usePersistedState();
  const navigate = useNavigate();
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const { encryptedValue: queryEncryptedValue, key: queryKey } = useExtractRawQueryParams();
  const [options, setOptions] = useState(items);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [drawer, setDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const [encryptedValue, setEncryptedValue] = useState(queryEncryptedValue);
  const [key, setKey] = useState(queryKey);
  const [personData, setPersonData] = useState(customization?.personData);
  const [picture, setPicture] = useState('');

  const styles = {
    container: {
      color: '#FFFFFF',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: "45px",
      backgroundColor: "#2c3e50",
      minHeight: '100vh',
      overflow: 'hidden',
    },
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '4px',
    },
    headerBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      '@media (max-width: 768px)': {
        alignItems: 'flex-start',
      }
    },
    actions: {
      display: 'flex',
      gap: '20px',
      '@media (max-width: 768px)': {
        flexDirection: 'column',  
        alignItems: 'flex-start', 
        width: '100%',  
        marginTop: '10px', 
        justifyContent: 'flex-start',
      },
    },
    reportCards: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start', // Align items to the left instead of centering
      marginTop: '20px',  // Add some spacing between header and the cards
      width: '100%',
      flex: 1,
    },
    selectLogoutTypography: {
      fontSize: '1rem',
      cursor: 'pointer',
      '@media (max-width: 768px)': {
        fontSize: '0.875rem',
      },
    },
    viewHealthInfoText: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      '@media (max-width: 768px)': {
        fontSize: '1.25rem', 
      },
    },
  };

  useEffect(() => {
    if (customization?.userData?.gender === "F" && customization?.userData?.age >= 10) {
      setOptions(prevOptions => {
        if (!prevOptions.find(option => option.value === 'menstrual-cycle')) {
          const menstrualCycleOption = {
            label: "Menstrual Cycle",
            value: 'menstrual-cycle',
            route: '/hlog/menstrual',
            icon: MenstrualSvg,
            reportKey: "menstrualCycleStatus"
          };

          const updatedOptions = [
            ...prevOptions.slice(0, 2), 
            menstrualCycleOption, 
            ...prevOptions.slice(2)
          ];

          return updatedOptions;
        }
        return prevOptions;
      });
    }
  }, [customization]);

  useEffect(() => {
    function isEmpty(value) {
      return value == null || value === "";
    }
    if (isEmpty(queryEncryptedValue) && isEmpty(queryKey)){
      if (sessionStorage.getItem('reduxState1') == null && isEmpty(customization?.userData?.hlogPersonId) && isEmpty(customization?.userData?.doctorName)) {
        navigate(`/${window.location.search}`);
      }
    }
    const matchedPerson = customization?.personData?.find(person =>
      person?.personDetails?.firstName === customization?.userData?.firstName &&
      person?.personDetails?.age === customization?.userData?.age
    );
    if (matchedPerson) {
      setEncryptedValue(matchedPerson.encryptedValue);
      setKey(matchedPerson.uid);
      setPersonData(customization.personData);
      setPicture(matchedPerson.personPictureBase64String);
    } else {
      setEncryptedValue(queryEncryptedValue);
      setKey(queryKey);
      setPersonData([]); 
      setPicture(''); 
    }
  }, [customization?.personData, customization?.userData?.firstName, customization?.userData?.age, queryEncryptedValue, queryKey, customization?.userData]);

  const navigateTo = async (item) => {
    try {
      if (item.route === '/hlog/landing') {
        navigate(`/hlog/landing${window.location.search}`);
      } else {
        navigate(`${item.route}${window.location.search}`, { state: { url: `${process.env.REACT_APP_BASEURL}/report/getreports/?encryptedValue=${encryptedValue}&uid=${key}&reportType=${item.value}&uploadType=${item.uploadType}`, name: item.label } });
      }
    } catch (e) {
      alert('Something went wrong! Please retry.');
    }
  };

  const handleLogout = async() => {
    try {
      const payload = personData.map(value => ({
        encryptedValue: value.encryptedValue,
        uid: value.uid,
      }));
      const result = await webLogout(payload);
      const allSuccess = result.every(item => item.status === true);
      if (allSuccess) {
        sessionStorage.removeItem('reduxState1');
        sessionStorage.removeItem('reduxState2');
        toast.success("Logout Successful !", { position: toast.POSITION.TOP_RIGHT });
        navigate(`/hlog/${window.location.search}`); 
      } else {
        toast.error("Logout Failed!", { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      toast.error("Something went wrong !", { position: toast.POSITION.TOP_RIGHT });
      console.error("Logout Error:", error); 
    }
  };

  let marginLeft = '0%';  
  if (personData?.length === 1 && !queryEncryptedValue ) {
    if (isMobile){
      marginLeft = '20%';
    }else {
      marginLeft = '6%';
    }
  } else if (personData?.length > 1 && !queryEncryptedValue ) {
    if (isMobile){
      marginLeft = '20%';
    }else {
      marginLeft = '14%';
    }
  }

  return (
    <div style={styles.container}>
        <Box style={styles.header}>
          <Box style={styles.headerBox}>
            <Box sx={{ flex: 1,  display: 'flex', justifyContent: 'center', marginLeft:marginLeft }}>
              <Logo />
            </Box>
            <Box sx={{ display: 'flex', gap: '20px', flexDirection: isMobile ? 'column' : 'row' }}>
            {personData?.length > 1 && !queryEncryptedValue && (
              isMobile ? (
                <FormControl sx={{ minWidth: '100%', height: '26px' }}>
                  <Select
                    value=""
                    IconComponent={open ? ArrowDropUpIcon : ArrowDropDownIcon}
                    open={open}
                    onOpen={() => setOpen(true)} 
                    onClose={() => setOpen(false)} 
                    displayEmpty
                    sx={{
                      backgroundColor: '#41586E',
                      borderRadius: '12px',
                      paddingLeft: '2px',
                      paddingRight: '2px',
                      marginLeft: '4px',
                      '& .MuiSelect-select': {
                        backgroundColor: '#41586E',
                        display: 'flex',
                        alignItems: 'center',
                        paddingX: '5px',
                        paddingY: '5px',
                      },
                      '& .MuiSelect-icon': {
                        top: '50%',
                        transform: 'translateY(-50%) translateX(16%)',
                        fontSize: '36px',
                        color: '#49C1C3',
                      },
                      width: '100%',
                    }}
                  >
                    <MenuItem value="">
                      <ListItemIcon>
                        <Avatar sx={{ width: 30, height: 30 }} src={`data:image/png;base64,${picture}`} />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem onClick={() => setDrawer(true)} value="Select Person">
                      Select Person
                    </MenuItem>
                    <MenuItem onClick={handleLogout} value="Logout">
                      Logout
                    </MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <>
                  <Typography variant="h4" color="white" onClick={() => setDrawer(true)} sx={{ cursor: 'pointer' }}>
                    Select Person
                  </Typography>
                  <Typography variant="h4" color="white" onClick={handleLogout} sx={{ cursor: 'pointer' }}>
                    Logout
                  </Typography>
                </>
              )
            )}
            {personData?.length === 1 && !queryEncryptedValue && (
              isMobile ? (
              <FormControl sx={{ minWidth: '100%', height: '26px' }}>
                <Select
                  value=""
                  IconComponent={open ? ArrowDropUpIcon : ArrowDropDownIcon}
                  open={open}
                  onOpen={() => setOpen(true)} 
                  onClose={() => setOpen(false)} 
                  displayEmpty
                  sx={{
                    backgroundColor: '#41586E',
                    borderRadius: '12px',
                    paddingLeft: '2px',
                    paddingRight: '2px',
                    marginLeft: '4px',
                    '& .MuiSelect-select': {
                      backgroundColor: '#41586E',
                      display: 'flex',
                      alignItems: 'center',
                      paddingX: '5px',
                      paddingY: '5px',
                    },
                    '& .MuiSelect-icon': {
                      top: '50%',
                      transform: 'translateY(-50%) translateX(16%)',
                      fontSize: '36px',
                      color: '#49C1C3',
                    },
                    width: '100%',
                  }}
                >
                  <MenuItem value="">
                    <ListItemIcon>
                      <Avatar sx={{ width: 30, height: 30 }} src={`data:image/png;base64,${picture}`} />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem onClick={handleLogout} value="Logout">
                    Logout
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Typography variant="h4" color="white" onClick={handleLogout} sx={{ cursor: 'pointer' }}>
                Logout
              </Typography>
            ))}
        </Box>
        </Box>
      <Typography variant="h3" color='white' fontWeight='bold'>View Health Info</Typography>
      </Box>
      <NameTile />
      <Grid container spacing={2} style={styles.reportCards}>
        {options?.map((option, idx) =>
        <Grid item xs={12} sm={6} md={4}>
          <ReportCard
            key={option.value + idx}  // Use both value and index to ensure unique keys
            title={option.label}
            Icon={option.icon}
            disabled={customization?.userData ? customization?.userData[option?.reportKey] === "not-avaialble" : false}
            onClick={() => navigateTo(option)}
          />
          </Grid>
        )}
      </Grid>
      {drawer && <DrawerPerson personData={personData} setDrawer={setDrawer}/>}
    </div>
  );
};

export default PatientDashboard;

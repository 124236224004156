import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {ThemeProvider} from "@mui/material/styles";
import {CssBaseline, StyledEngineProvider} from "@mui/material";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import {SET_USER_NAME} from "store/actions";
import {SET_PERSON_DETAILS} from "store/actions";
import {fetchPersonDetails} from "services/Api";

// ==============================|| APP ||============================== //

import InvalidLinkMessage from "ui-component/InvalidLinkMessage";
import LoadingSpinner from "ui-component/spinners/LoadingSpinner";
import useExtractQueryParams from "hooks/useExtractQueryParams";
import {toast} from "react-toastify";
import usePersistedState from "hooks/usePersistedState";

const App = () => {
  usePersistedState();
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  const {encryptedValue, key} = useExtractQueryParams();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if ( encryptedValue && key ) {
      fetchPersonDetails(encryptedValue, key).then(result => {
        dispatch({type: SET_USER_NAME, data: result});
      }).catch(e => {
        setErrorMessage("Link is Invalid or Expired!");
      }).finally(() => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!encryptedValue && !key) {
      const cleanupTimer = startInactivityTimer();
      return () => cleanupTimer();
    }
  }, []);

  const handleLogout = async () => {
    try {
      sessionStorage.clear();
      dispatch({ type: SET_USER_NAME, data: undefined });
      dispatch({ type: SET_PERSON_DETAILS, data: undefined });
      window.location.replace(`/hlog${window.location.search}`);
      toast.success("Session Time Out !", { position: toast.POSITION.TOP_RIGHT });
    } catch (error) {
      toast.error("Session Time Out Failed !", { position: toast.POSITION.TOP_RIGHT });
      console.error("Logout Error:", error);
    }
  };

  const startInactivityTimer = () => {
    let timeout;
    const resetTimer = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        handleLogout(); 
      },  3600000); 
    };
    const events = ['mousemove', 'keydown', 'click'];
    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });
    resetTimer();
    return () => {
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
      clearTimeout(timeout);
    };
  };

  return (
    <>
      <ToastContainer />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            {loading ? <LoadingSpinner /> : !!errorMessage ? <InvalidLinkMessage /> : <Routes />}
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;

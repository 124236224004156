import './drawerPage.scss';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import CustomDrawer from 'ui-component/drawer/Drawer';
import CloseButton from 'ui-component/closeButton/CloseButton';
import medugoLogo from '../../assets/images/medugo_logo.png';
import personSelected from '../../assets/images/PersonSelected.png';
import BottomButton from 'ui-component/bottomButton/bottomButton';
import BackButton from 'ui-component/backButton/BackButton';
import { useDispatch } from 'react-redux';
import {SET_USER_NAME} from "store/actions";
import Avatar from '../../assets/images/Avatar.png';

const DrawerPerson = ({personData , setDrawer}) => {
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const [open, setOpen] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectPerson, setSelectPerson] = useState(personData);
    const dispatch = useDispatch();

    useEffect(()=>{
        setSelectPerson(personData);
    },[]);

    const handleClose = () => setDrawer(false);

    const handleDrawerClose = () => {
            setDrawer(false);
            navigate(`${"/hlog/dashboard"}${window.location.search}`,{  
            state: {
            personData:personData,
        }});
    };

    const handleSelectedPerson = (index) => {
       const personDetail = selectPerson[index];
       const result = personDetail?.personDetails;
       try {
            const serializedState1 = JSON.stringify(result);
            sessionStorage.setItem('reduxState1', serializedState1);
        } catch (err) {
            console.error("Could not save state to localStorage", err);
        }
       dispatch({type: SET_USER_NAME, data: result});
       setDrawer(false);
       navigate(`${"/hlog/dashboard"}${window.location.search}`,{  
            state: {
            personData:personData,
            picture: personDetail.personPictureBase64String,
        }
    });
    };

    const getFullRelationship = (abbreviation) => {
        const relationshipMap = {
            SP: "Spouse",
            SN: "Son",
            DG: "Daughter",
            FT: "Father",
            MT: "Mother",
            BT: "Brother",
            ST: "Sister",
            FIL: "Father in Law",
            MIL: "Mother in Law",
            SL : "Self",
        };
        return relationshipMap[abbreviation] || abbreviation;
    };

    return (
        <CustomDrawer open={open} onClose={handleClose}>
            {isDesktop && (
                <Box className="drawer-logo">
                    <img src={medugoLogo} alt="Medugo Logo" className="drawer-medugo-logo" />
                </Box>
            )}
            <Box className="drawer-header">
                {isDesktop && (
                    <BackButton onClick={handleDrawerClose} />
                )}
                <Typography className="drawer-title">Select Person</Typography>
                {!isDesktop && (
                    <CloseButton onClick={handleDrawerClose} />
                )}
            </Box>
            <Box className="drawer-content">
                {selectPerson?.map((item, index) => (
                    <Box key={index} className="person-box">
                        <Box
                            className={`person-circle ${selectedIndex === index ? 'selected' : ''}`}
                            onClick={() => setSelectedIndex(index)}
                        >
                            <img 
                                src={item.personPictureBase64String ? `data:image/png;base64,${item.personPictureBase64String}` : Avatar}
                                alt="Person Image" 
                                className= "person-image"
                                style={{
                                    backgroundColor: item.personPictureBase64String ? 'transparent' : '#41586E'
                                }}
                            />
                            {selectedIndex === index && (
                                <img src={personSelected} alt="Tick" className="tick-logo" />
                            )}
                        </Box>
                        <Typography className="person-name">{item.personDetails.firstName}</Typography>
                        <Typography className="person-name">{getFullRelationship(item.relationship)}</Typography>
                    </Box>
                ))}
            </Box>
            <Box className="drawer-button-container">
                <BottomButton onClick={() => {
                    handleSelectedPerson(selectedIndex);

                    }} 
                    buttonName='Continue' />
            </Box>
        </CustomDrawer>
    );
};

export default DrawerPerson;

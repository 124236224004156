import { Card, Typography, Grid } from '@mui/material';
import BloodReportSvg from 'assets/images/BloodReportSvg';
import CTScanSvg from 'assets/images/CTScanSvg';
import MriScanSvg from 'assets/images/MriScanSvg';
import PetScanSvg from 'assets/images/PetScanSvg';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import RightArrowSvg from 'assets/images/icons/RightArrowSvg';
import { useTheme } from "@mui/material/styles";
import { useSelector } from 'react-redux';
import useExtractRawQueryParams from 'hooks/useExtractQueryParams';
import NameTile from 'ui-component/NameTile';
import Logo from 'ui-component/Logo';
import BackButton from 'ui-component/backButton/BackButton';
import UltraSoundSvg from 'assets/images/UltraSoundSvg';
import XRaySvg from 'assets/images/XRaySvg';
import MamogramSvg from 'assets/images/MamogramSvg';
import LunFunctionSvg from 'assets/images/LungFunctionSvg';
import EchoCardioSvg from 'assets/images/EchoCardioSvg';
import { Upload } from '@mui/icons-material';
import usePersistedState from 'hooks/usePersistedState';

const items = [
  {
    label: 'CT Scan Reports',
    value: 'ct',
    uploadType: "CT",
    route: '/hlog/pdf',
    icon: CTScanSvg,
    reportKey: "ctStatus"
  },
  {
    label: 'MRI Scan Reports',
    value: 'mri',
    uploadType: "MRI",
    route: '/hlog/pdf',
    icon: MriScanSvg,
    reportKey: "mriStatus"
  },
  {
    label: 'PET Scan Reports',
    value: 'pet',
    uploadType: "PET",
    route: '/hlog/pdf',
    icon: PetScanSvg,
    reportKey: "petStatus"
  },
  {
    label: 'Ultrasound Sonogram Reports',
    value: 'usg',
    uploadType: "USG",
    route: '/hlog/pdf',
    icon: UltraSoundSvg,
    reportKey: "ultrasoundSonogramStatus"
  },
  {
    label: 'Mammogram Reports',
    value: 'mammo',
    uploadType: "MAMMO",
    route: '/hlog/pdf',
    icon: MamogramSvg,
    reportKey: "mammogramStatus"
  },
  {
    label: 'Echocardiogram Reports',
    value: 'echo',
    uploadType: "ECHO",
    route: '/hlog/pdf',
    icon: EchoCardioSvg,
    reportKey: "echocardiogramStatus"
  },
  {
    label: 'X RAY Reports',
    value: 'xray',
    uploadType: "XRAY",
    route: '/hlog/pdf',
    icon: XRaySvg,
    reportKey: "xrayStatus"
  },
  {
    label: 'Lung Function Test Reports',
    value: 'lft',
    uploadType: "LFT",
    route: '/hlog/pdf',
    icon: LunFunctionSvg,
    reportKey: "lungFunctionTestStatus"
  },
];

const ReportCard = ({ title, Icon, disabled, onClick }) => {
  const theme = useTheme();
  const styles = {
    reportCard: {
      border: '1px solid #49C1C3',
      borderRadius: '5px',
      margin: '5px 0',
      padding: '17px',
      display: 'flex',
      alignItems: 'center',
      cursor: disabled ? 'not-allowed' : 'pointer',
      transition: 'background-color 0.3s ease',
      backgroundColor: disabled ? '#41586E' : theme.palette.background.paper,
      opacity: disabled ? 0.4 : 1,
    },
  };

  return (
    <Card style={styles.reportCard} onClick={() => !disabled && onClick()}>
      <Icon />
      <Typography
        variant="h3"
        color="white"
        fontWeight="bold"
        textAlign="left"
        flex={1}
        marginLeft={2}
      >
        {title}
      </Typography>
      <RightArrowSvg />
    </Card>
  );
};

const LabScan = () => {
  usePersistedState();
  const navigate = useNavigate();
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const [options] = useState(items);
  const { encryptedValue: queryEncryptedValue, key: queryKey } = useExtractRawQueryParams();
  const [encryptedValue, setEncryptedValue] = useState(queryEncryptedValue);
  const [key, setKey] = useState(queryKey);

  useEffect(() => {
    const matchedPerson = customization?.personData?.find(person =>
      person?.personDetails?.firstName === customization?.userData?.firstName &&
      person?.personDetails?.age === customization?.userData?.age
    );
    if (matchedPerson) {
      setEncryptedValue(matchedPerson.encryptedValue);
      setKey(matchedPerson.uid);
    } else {
      setEncryptedValue(queryEncryptedValue);
      setKey(queryKey);
    }
  }, [customization?.personData, customization?.userData?.firstName, customization?.userData?.age, queryEncryptedValue, queryKey]);


  const styles = {
    container: {
      color: '#FFFFFF',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: "45px",
      backgroundColor: "#2c3e50",
      minHeight: '100vh',
    },
    header: {
      textAlign: 'center',
      width: '100%',
      marginTop: '5px',
    },
    reportCards: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start', // Align items to the left instead of centering
      marginTop: '20px',  // Add some spacing between header and the cards
    },
  };


  const handleBack = () => {
    navigate(`/hlog/dashboard${window.location.search}`);
  };


  const navigateTo = async (item) => {
    try {
      if (item.route === '/hlog/dashboard') {
        navigate(`/hlog/dashboard${window.location.search}`);
      } else {
        navigate(`${item.route}${window.location.search}`,{ state: { url: `${process.env.REACT_APP_BASEURL}/report/getreports/?encryptedValue=${encryptedValue}&uid=${key}&reportType=${item.value}&uploadType=${item.uploadType}`,name: item.label } });
      }
    } catch (e) {
      alert('Something went wrong! Please retry.');
    }
  };

  return (
    <div style={styles.container}>
        <div>
            <BackButton  onClick={handleBack}/>
        </div>
    <header style={styles.header}>
      <Logo/>
      <Typography variant="h3" color='white' fontWeight='bold'>View Scan & X Ray Reports</Typography>
    </header>
    <NameTile />
    <Grid container spacing={2} style={styles.reportCards}>
        {options?.map((option, idx) => (
          <Grid item xs={12} sm={6} md={4}>
          <ReportCard key={option.value} title={option.label} Icon={option.icon} disabled={ customization.userData[option.reportKey] === "not-avaialble"} onClick={() => navigateTo(option)} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};


export default LabScan;
